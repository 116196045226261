body {
    background-color: #282d36;
    -ms-overflow-style: none;
    min-height: 100vh;
}

body::-webkit-scrollbar {
    display: none;
}

#root {
    min-width: 100%;
}

p {
    font-size: 22px;
    margin: 0;
}

span {
    font-size: 22px;
    margin: 0;
}

/* Profile stuff */

.online,.away {
    color: #4b90ab;
}

.online.friendName,.away.friendName {
    color: #66c1e5;
}

.inGame {
    color: #90c056;
}

.inGame.friendName {
    color: #d1eab4;
}

.friendStatus {
    overflow: hidden;
    white-space: nowrap;
}

/* Card stuff */

.card {
    border: 0;
    margin: 4px;
    width: '22rem';
}
.card:hover {
    margin: 0 !important;
    border: #7c7b94;
    border-width: 4px;
    border-style: solid;
    width: '22rem';
}

.friendSelected {
    margin: 0 !important;
    border: #7c7b94;
    border-width: 4px;
    border-style: solid;
    width: '22rem';
}

.friendsCard {
    cursor: pointer;
    background-color: #1f2127;
}

/* Game stuff */
.col-md-12 .position-relative:hover .gameCard {
    cursor: pointer;
    filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
    opacity: .3;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.gameCol {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.compGameText {
    font-size: 20px;
    color: white;
    margin-bottom: 25px;
}

.gameInfo {
    background-color: #212329;
    color: white;
    /* border: #7c7b94;
    border-width: 4px;
    border-style: solid; */
    -webkit-touch-callout:default;
    -webkit-user-select:text;
    -moz-user-select:text;
    -ms-user-select:text;
    user-select:text;
}

.steamTags {
    background-color: #293d4b;
    color: #7cc0f0;
    border: 0;
    padding: 2px 8px 2px 8px;
    margin-right: 4px;
}

/* Navbar stuff */

.navbar {
    margin-bottom: 20px;
}

.navbar-dark {
    background-color: #212329 !important;
}


/* Friends nav */
.friendsNav {
    margin-bottom: 0px !important;
    color: #ffffff
}



.arrow-down {
    width: 0; 
    height: 0; 
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid rgb(0, 0, 0);
  }

.middleTop {
    transition: .3s ease;
    opacity: 0;
    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    text-align: center;
    width: 80%;
  }

.middleBottom {
    transition: .4s ease;
    opacity: 0;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}  

.col-md-12:hover .position-relative:hover .middleTop, .col-md-12 .position-relative:hover .middleBottom {
    opacity: 1;
    cursor: pointer;
}

.text {
    color: white;
    font-size: 25px;
    line-height: 1.2;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.text svg {
    vertical-align: 0 !important;
}
.underline {
    cursor: pointer;
    text-decoration: underline;
}
